import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Profile = () => import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Notifications = () => import(/* webpackChunkName: "common" */"@/pages/Notifications.vue");
const Icons = () => import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () => import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
// const TableList = () => import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");
const Login = () => import(/* webpackChunkName: "common" */ "@/pages/Login.vue");
const Register = () => import(/* webpackChunkName: "common" */ "@/pages/Register.vue");

const DashboardMensal = () => import(/* webpackChunkName: "dashboard" */"@/pages/DashboardMensal.vue");
const DashboardSemanal = () => import(/* webpackChunkName: "dashboard" */"@/pages/DashboardSemanal.vue");
const DashboardDiario = () => import(/* webpackChunkName: "dashboard" */"@/pages/DashboardDiario.vue");
const DashboardTabela = () => import(/* webpackChunkName: "dashboard" */"@/pages/DashboardTabela.vue");
const DashboardComissoes = () => import(/* webpackChunkName: "dashboard" */"@/pages/DashboardComissoes.vue");
const DashboardAfiliados = () => import(/* webpackChunkName: "dashboard" */"@/pages/DashboardAfiliados.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/login",
    children: [
      {
        path: "dashboard/mensal",
        name: "mensal",
        component: DashboardMensal
      },
      {
        path: "dashboard/semanal",
        name: "semanal",
        component: DashboardSemanal
      },
      {
        path: "dashboard/diario",
        name: "diario",
        component: DashboardDiario
      },
      {
        path: "dashboard/tabela",
        name: "tabela",
        component: DashboardTabela
      },
      {
        path: "dashboard/comissoes",
        name: "comissoes",
        component: DashboardComissoes
      },
      {
        path: "dashboard/afiliados",
        name: "afiliados",
        component: DashboardAfiliados
      },
      {
        path: "profile",
        name: "profile",
        component: Profile
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notifications
      },
      {
        path: "icons",
        name: "icons",
        component: Icons
      },
      {
        path: "maps",
        name: "maps",
        component: Maps
      },
      {
        path: "typography",
        name: "typography",
        component: Typography
      },
      {
        path: "register",
        name: "register",
        component: Register
      },
      {
        path: "login",
        name: "login",
        component: Login
      }
    ]
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;

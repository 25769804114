<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/dashboard/mensal" name="Mensal" icon="tim-icons icon-calendar-60" v-if="getUsuario()"/>
        <sidebar-link to="/dashboard/semanal" name="Semanal" icon="tim-icons icon-chart-pie-36" v-if="getUsuario()"/>
        <sidebar-link to="/dashboard/diario" name="Diário" icon="tim-icons icon-chart-bar-32" v-if="getUsuario()"/>
        <sidebar-link to="/dashboard/afiliados" name="Afiliados" icon="tim-icons icon-molecule-40" v-if="isMaster"/>
        <sidebar-link to="/dashboard/tabela" name="Primeiro Depósito" icon="tim-icons icon-paper" v-if="getUsuario()"/>
        <sidebar-link to="/dashboard/comissoes" name="Comissões" icon="tim-icons icon-money-coins" v-if="getUsuario()"/>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  data() {
      return {
        isMaster: false,
      }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    getUsuario() {
      try {
        let user = JSON.parse(localStorage.AUTH_USER)
        return user
      } catch (error) {
        console.error(error)
        localStorage.AUTH_USER = null
      }
    },
    isUserMaster() {
      let user = this.getUsuario()
      if (user.id) {
        this.$http
        .get(this.$API+'dashboard/master/afiliados/'+user.id)
        .then(response => {
          this.isMaster = response.data && Object.keys(response.data).length > 0
        })
        .catch(function (error) {
              console.error(error)
        });   
      } else {
        this.isMaster = false
      }
    }
  },
  mounted() {
    this.isUserMaster()
  }
};
</script>
